/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

h3,
h4,
h5,
h6 {
  color: #34679f;
}

.wallet {
  height: 80px;
  max-width: 280px;
  /* max-height: 75px; */
}

button {
  background-color: transparent;
  border: 0 none;
}

button:disabled {
  opacity: .5;
}

input[type="text"],
input[type="email"],
input[type="numeric"] {
  font-size: 24px !important;
}

@media only screen and (max-width: 768px) {

  input[type="text"],
  input[type="email"],
  input[type="numeric"] {
    font-size: 16px !important;
  }
}

/* .bg-black {
  background-color: #000;
  padding: 20px;
} */
.tableContainer {
  overflow-x: scroll;
}

.tableContainer p {
  margin-left: 20px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
}

table th,
table td {
  font-size: 10px;
}

.previewImage {
  width: 320px;
  height: 240px;
  text-align: center;
}


.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 32px 0;
  margin: 0 -5%;
  overflow: hidden;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #03203f;
  color: #03203f;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #34679f;
  color: #34679f;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #34679f;
  color: #34679f;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #34679f;
  }

  50%,
  100% {
    background-color: rgba(255, 207, 55, 0.2);
  }
}

.ReactCrop img {
  max-height: 350px !important;
}

#interactive.viewport {
  width: 100%;
  height: 300px;
  /* border-radius: 10px; */
  /* background-color: #eee; */
}

#interactive.viewport canvas,
video {
  width: 100%;
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
}

#interactive.viewport canvas.drawingBuffer,
video.drawingBuffer {
  width: 100%;
  height: 300px;
}

.disclaimer {
  font-size: 13px; line-height: 1; padding: 10px 0; font-style: italic; color: #888; text-align: left;
}

#passDisclaimer {
  background-color: red;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px 7px;
  /* text-transform: uppercase; */
  font-weight: bold;
  text-align: center;
  line-height: 1;
  color: #fff;
  font-size: 13px;
}
#passDisclaimer h4 {
  color: #fff;
  font-size: 16px;
}
#passContainer {
  width: 310px;
  padding: 10px;
  border-radius: 10px;
  margin: 0 auto;
}
#passHeader {
  width: 290px;
  height: 100px;
}
#passLogo {
  width: 140px;
  padding: 10px;
  float: left;
}
#passLogo img {
  width: 100%;
}
#passPhoto {
  width: 140px;
  margin-left: 10px;
  float: right;
}
#passPhoto img {
  border-radius: 5px;
  border: 1px solid #ffffffcc;
}
#passCredits {
  width: 120px;
  margin-left: 30px;
  float: right;
  text-align: right;
}
#passTicketholder {
  clear: both;
  padding: 10px;
}
#passTicketPurchaser {
  clear: both;
  padding: 10px;
}
#passOrderDate {
  float: left;
  width: 130px;
  padding: 10px;
}
#passExpireDate {
  float: left;
  width: 130px;
  padding: 10px;
}
#passProduct {
  clear: both;
  padding: 10px;
}
#passBarcode {
  margin: 20px 1px 1px;
  border-radius: 5px;
}
.passLabel {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  opacity: .65;
}
.passValue {
  font-size: 20px;
  line-height: 18px;
  margin-top: 3px;
}
.passValueLarge {
  font-size: 24px;
  line-height: 21px;
  margin-top: 3px;
}
.passValue em, .passValueLarge em {
  font-size: 10px;
  line-height: 10px;
  /* font-weight: bold; */
}

.samples td, .samples th {
  font-size: 14px;
  padding: 5px 3px !important;
  line-height: .95;
}

.samples .selected {
  background-color: lightyellow;
}
.modal {
  opacity: 1;
}
#voucherButton {
  background-color: #000;
  color: #fff;
  padding: 10px;
  width: 100%;
  border-radius: 15px;
  font-size: 30px;
}